<template>
  <b-container id="faq-question-page">
    <b-row>
      <div class="page-question-nav">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M15 18L9 12L15 6" stroke="#2FB2AE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <a :aria-label="'Back to ' + getCategoryName(categoryId) + ' Link'"
          @click="logFaqCategoryNavigation(categoryId, { action: 'visit', type: 'link', categoryName: getCategoryName(categoryId) })">
          Back to {{ getCategoryName(categoryId) }}
        </a>
      </div>
    </b-row>
    <ct-centered-spinner v-if="!loaded" />
    <div v-if="loaded" class="faq-page-container">
      <div class="faq-question-header">
        <h1>{{ page.question }}</h1>
        <hr>
      </div>
      <div class="time-subheader">
        <clock />
        <p><i>Updated:</i> {{ formatPageDate(page.updated_at) }}</p>
      </div>
      <div class="faq-page-content">
        <p><span v-html="pageContent" /></p>
        <div v-if="hasValidVideo" class="faq-page-video">
          <br>
          <video controls>
            <source
              :src="page.video_url"
              type="video/mp4"
            >
          </video>
          <br>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import * as DOMPurify from 'dompurify'
import { faqMixin } from '@/mixins/faqMixin'

export default {
  name: 'FaqPage',
  components: {
    CtCenteredSpinner,
    Clock: () => import('@images/ui/clock.svg'),
  },
  mixins: [faqMixin],
  data() {
    return {
      page: null,
      loaded: false,
      pageCreationDate: null,
    }
  },
  computed: {
    ...mapGetters('account', ['account']),
    categoryId() {
      return this.$route.params.categoryId
    },
    pageId() {
      return this.$route.params.pageId
    },
    hasValidVideo() {
      return !!this.page.video_url && this.page.video_url.includes('drive.google.com')
    },
    pageContent() {
      return DOMPurify.sanitize(this.page.content)
    },
  },
  async mounted() {
    if(!this.getPage(this.pageId)) {
      await this.fetchFaqInformation()
    }
    this.page = this.getPage(this.pageId)
    this.loaded = true
  },
  methods: {
    getCategoryName(categoryId) {
      const category = this.faqInformation?.find(cat => cat.id === categoryId)
      return category ? category.name : null
    },
    getPage(pageId) {
      //Uses flatmap to create a flat array of all pages from all categories
      const foundPage = this.faqInformation
        .flatMap(category => category.faq_pages)
        .find(page => page.id === pageId)

      return foundPage || null
    },
    formatPageDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' }
      return new Date(dateString).toLocaleDateString(undefined, options)
    },
  },
}
</script>

<style lang="scss">
#faq-question-page {
  .page-question-nav {
    display: flex;
    align-items: center;
    margin-top: 77px;
    a {
      font-weight: 600;
      color: #2FB2AE;
    }
    a:hover {
      cursor: pointer;
    }
    svg {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  .faq-question-header {
    hr {
      width: 100%;
      margin-bottom: 8px;
    }
    h1 {
      font-size: 24px;
      margin-top: 64px;
    }
  }
  .time-subheader {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    svg {
      margin-right: 6px;
    }
    p {
      margin: 0;
      color: #707070;
    }
  }
  .faq-page-content {
    white-space: pre-wrap;
    max-width: 832px;
    width: 95%;
    margin-bottom: 72px;
  }
  video {
    width: 584px;
    border-radius:6px;
  }
}
</style>
