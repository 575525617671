var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "faq-question-page" } },
    [
      _c("b-row", [
        _c("div", { staticClass: "page-question-nav" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M15 18L9 12L15 6",
                  stroke: "#2FB2AE",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
          _c(
            "a",
            {
              attrs: {
                "aria-label":
                  "Back to " + _vm.getCategoryName(_vm.categoryId) + " Link",
              },
              on: {
                click: function ($event) {
                  _vm.logFaqCategoryNavigation(_vm.categoryId, {
                    action: "visit",
                    type: "link",
                    categoryName: _vm.getCategoryName(_vm.categoryId),
                  })
                },
              },
            },
            [
              _vm._v(
                "\n        Back to " +
                  _vm._s(_vm.getCategoryName(_vm.categoryId)) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
      !_vm.loaded ? _c("ct-centered-spinner") : _vm._e(),
      _vm.loaded
        ? _c("div", { staticClass: "faq-page-container" }, [
            _c("div", { staticClass: "faq-question-header" }, [
              _c("h1", [_vm._v(_vm._s(_vm.page.question))]),
              _c("hr"),
            ]),
            _c(
              "div",
              { staticClass: "time-subheader" },
              [
                _c("clock"),
                _c("p", [
                  _c("i", [_vm._v("Updated:")]),
                  _vm._v(" " + _vm._s(_vm.formatPageDate(_vm.page.updated_at))),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "faq-page-content" }, [
              _c("p", [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.pageContent) },
                }),
              ]),
              _vm.hasValidVideo
                ? _c("div", { staticClass: "faq-page-video" }, [
                    _c("br"),
                    _c("video", { attrs: { controls: "" } }, [
                      _c("source", {
                        attrs: { src: _vm.page.video_url, type: "video/mp4" },
                      }),
                    ]),
                    _c("br"),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }